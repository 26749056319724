import type { Commitment } from '@solana/web3.js'
import { Notify } from 'quasar'
import {
  DEFAULT_CONFIRM_TIMEOUT,
  // DEFAULT_MONITOR_COMMITMENT,
  DEFAULT_SEND_TIMEOUT,
  TELEGRAM_ANNOUNCEMENT_URL,
} from '~/config'
import { useConnectionStore } from '~/store'

type MonitorTransactionParams = {
  onSuccess?: (signature: string) => void
  onError?: (reason: string) => void
  commitment?: Commitment
  sendTimeout?: number
  confirmTimeout?: number
  idx?: string
}

export function useMonitorTransaction() {
  const connectionStore = useConnectionStore()
  const { t } = useTranslation()

  const cluster = toRef(connectionStore, 'cluster')
  const sending = ref(false)

  async function monitorTransaction(
    signatureOrPromise: Promise<string> | string,
    {
      onSuccess,
      onError,
      idx,
      commitment, // = 'confirmed',
      sendTimeout = DEFAULT_SEND_TIMEOUT,
      confirmTimeout = DEFAULT_CONFIRM_TIMEOUT,
    }: MonitorTransactionParams = {},
  ): Promise<void> {
    idx = idx ?? ''

    let dismiss = Notify.create({
      progress: true,
      type: 'ongoing',
      message: idx ? `Sending transaction "${idx}" ...` : 'Sending transaction...',
      timeout: sendTimeout,
    })

    sending.value = true

    const closeAction = {
      label: 'Close',
      color: 'white',
    }

    let signature = ''
    try {
      signature = String(await signatureOrPromise)
    } catch (error: any) {
      sending.value = false
      dismiss()
      if (error?.message && !String(error?.message).startsWith('User rejected')) {
        Notify.create({
          message: idx ? `Transaction "${idx}" error` : 'Transaction error',
          caption: error?.message,
          type: 'negative',
          timeout: 0,
          actions: [closeAction],
        })
      }
      return
    }

    // https://solscan.io/tx/{id}
    const explorerUrl = `https://explorer.solana.com/tx/${signature}?cluster=${cluster.value}`

    const exploreAction = {
      label: 'Explore',
      color: 'white',
      target: '_blank',
      href: explorerUrl,
      onClick: () => false,
    }

    const telegramAction = {
      label: 'Telegram',
      color: 'white',
      target: '_blank',
      href: TELEGRAM_ANNOUNCEMENT_URL,
    }

    try {
      dismiss()

      dismiss = Notify.create({
        // spinner: true,
        progress: true,
        type: 'ongoing',
        message: idx ? `Confirming transaction "${idx}" ...` : 'Confirming transaction...',
        actions: [exploreAction],
        timeout: confirmTimeout,
      })

      const latestBlockHash = await connectionStore.connection.getLatestBlockhash()
      const res = await connectionStore.connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature,
      }, commitment)

      dismiss()

      if (res.value.err) {
        // console.error(res.value.err);
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(JSON.stringify(res.value.err))
      }

      dismiss = Notify.create({
        message: idx ? `Transaction "${idx}" confirmed` : 'Transaction confirmed',
        type: 'positive',
        actions: [exploreAction],
      })

      if (onSuccess) {
        onSuccess(signature)
      }
    } catch (error: any) {
      dismiss()
      let message = error.message
      let useHtml = false
      if (message.includes('block height exceeded')) {
        useHtml = true
        message = `<span>${message}</span><br><strong>${t('error.changeFee')}</strong>`
      }
      Notify.create({
        message: idx ? `Transaction "${idx}" error` : 'Transaction error',
        caption: message,
        html: useHtml,
        type: 'negative',
        timeout: 0,
        actions: [exploreAction, telegramAction, closeAction],
      })

      if (onError) {
        onError(error)
      }
      console.error(error)
    } finally {
      sending.value = false
    }
  }

  return { monitorTransaction, sending }
}
