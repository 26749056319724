import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

import SolanaWallets from 'solana-wallets-vue'
import {
  AvanaWalletAdapter,
  BackpackWalletAdapter,
  BitpieWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  CoinhubWalletAdapter,
  HuobiWalletAdapter,
  HyperPayWalletAdapter,
  KrystalWalletAdapter,
  LedgerWalletAdapter,
  MagicEdenWalletAdapter,
  MathWalletAdapter,
  MoongateWalletAdapter2,
  NekoWalletAdapter,
  NufiWalletAdapter,
  OKXWalletAdapter,
  OntoWalletAdapter,
  PhantomWalletAdapter,
  SafePalWalletAdapter,
  SaifuWalletAdapter,
  SalmonWalletAdapter,
  SkyWalletAdapter,
  SolflareWalletAdapter,
  SolongWalletAdapter,
  SpotWalletAdapter,
  TipLinkWalletAdapter,
  TokenaryWalletAdapter,
  TokenPocketWalletAdapter,
  TorusWalletAdapter,
  TrezorWalletAdapter,
  TrustWalletAdapter,
  WalletConnectWalletAdapter,
  XDEFIWalletAdapter,
} from 'wallets'
import type { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  const walletConnectAdapter = new WalletConnectWalletAdapter()
  walletConnectAdapter.setWalletConnectConfigGetter(() => {
    const con = useConnectionStore()
    return {
      providerOpts: {
        metadata: {
          name: 'Jpool',
          description: 'Jpool Web3 Wallet',
          url: 'https://app.jpool.one',
          icons: ['https://avatars.githubusercontent.com/u/37784886'],
        },
      },
      network: con.cluster === 'mainnet-beta' ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet,
      rpcEndpoint: con.connection.rpcEndpoint,
    }
  })

  app.use(SolanaWallets as any, {
    wallets: [
      new MoongateWalletAdapter2({
        authMode: 'Ethereum',
        position: 'top-right',
      }),
      new MoongateWalletAdapter2({
        authMode: 'Google',
        position: 'top-right',
      }),
      new MoongateWalletAdapter2({
        authMode: 'Apple',
        position: 'top-right',
      }),
      new TipLinkWalletAdapter({
        clientId: import.meta.env.TIP_WALLET_KEY ?? '',
        title: 'JPool',
        theme: 'system',
      }),
      new PhantomWalletAdapter(),
      new BackpackWalletAdapter(),
      new OKXWalletAdapter(),
      new TrustWalletAdapter(),
      new SolflareWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new SolongWalletAdapter(),
      new CloverWalletAdapter(),
      new BitpieWalletAdapter(),
      new Coin98WalletAdapter(),
      new CoinhubWalletAdapter(),
      new SafePalWalletAdapter(),
      new TokenPocketWalletAdapter(),
      new MathWalletAdapter(),
      new OntoWalletAdapter(),
      new LedgerWalletAdapter(),
      new HyperPayWalletAdapter(),
      new SkyWalletAdapter(),
      new SaifuWalletAdapter(),
      new MagicEdenWalletAdapter(),
      new NufiWalletAdapter(),
      new NekoWalletAdapter(),
      new SpotWalletAdapter(),
      new AvanaWalletAdapter(),
      new KrystalWalletAdapter(),
      new HuobiWalletAdapter(),
      new SalmonWalletAdapter(),
      new XDEFIWalletAdapter(),
      new TorusWalletAdapter(),
      new TokenaryWalletAdapter(),
      new TrezorWalletAdapter(),
      walletConnectAdapter,
    ],
    autoConnect: true,
  })
}
