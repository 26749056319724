import i18next from 'i18next'
import countriesJson from '~/config/countries.json'

export function countryNameByIso2(iso2: string) {
  const fixedIso2 = iso2 === 'EN' ? 'GB' : iso2
  return countriesJson[fixedIso2] ?? fixedIso2
}

export function calculatePercentage(base: number, value: number) {
  if (base === 0) {
    return 0
  }
  const percentage = (value / base) * 100
  return percentage?.toFixed(2)
}

export function localesAll(label: string) {
  switch (label) {
    case 'All': return i18next.t('common.all')
    default: return label
  }
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Efficiently encodes a Uint8Array to a base64 string.
 */
export function base64Encode(bytes: Uint8Array): string {
  let binary = ''
  const len = bytes.length
  const chunkSize = 0x80_00 // 32KB chunks to avoid stack overflow
  for (let i = 0; i < len; i += chunkSize) {
    const chunk = bytes.subarray(i, i + chunkSize)
    binary += String.fromCodePoint(...chunk)
  }
  return btoa(binary)
}
