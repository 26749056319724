import VueHotjar from 'vue-hotjar-next'
import { HOTJAR_ID } from '~/config'
import type { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  if (!HOTJAR_ID || Number.isNaN(+HOTJAR_ID)) {
    return
  }
  app.use(VueHotjar, {
    id: +HOTJAR_ID,
    isProduction: true,
    snippetVersion: 6,
  })
}
