const SERVICES_ROUTE = 'validators'
const FLEX_ROUTE = 'flex-staking'
const JSTAKING_ROUTE = 'jstaking'

export function useApp() {
  const route = useRoute()
  const isServices = computed(() => route.path.includes(SERVICES_ROUTE))
  const isFlexStaking = computed(() => route.path.includes(FLEX_ROUTE))
  const isJstaking = computed(() => route.path.includes(JSTAKING_ROUTE))
  const isClient = computed(() => typeof document !== 'undefined')

  const validatorBasePath = computed(() => {
    if (isJstaking.value) {
      return JSTAKING_ROUTE
    }
    if (isFlexStaking.value) {
      return FLEX_ROUTE
    }
    return SERVICES_ROUTE
  })

  return {
    isClient,
    validatorBasePath,
    isServices,
    isJstaking,
    isFlexStaking,
  }
}
